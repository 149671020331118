<template>
  <b-tab title="Notifications">
    <template v-if="apiResponse && apiResponse.notificationResponse">
      <template v-if="apiResponse.notificationResponse.notificationErrorMsgs">
        <p
          v-for="(nError, nEIndex) in apiResponse.notificationResponse
            .notificationErrorMsgs"
          :key="`nE-${nEIndex}`"
          class="pl-2 text-danger"
        >
          {{ nError }}
        </p>
      </template>
      <template
        v-if="apiResponse.notificationResponse.notificationFormatResponse"
      >
        <div
          v-for="(nRes, nRIndex) in apiResponse.notificationResponse
            .notificationFormatResponse"
          :key="`nR-${nRIndex}`"
          class="pl-2 pt-2 notifications-list"
        >
          <p class="theme-font-bold">{{ nRes.heading }}</p>
          <p class="theme-font-bold mb-0 font-14">
            {{ nRes.title }}
          </p>
          <ul>
            <li
              class="mb-3 font-14"
              v-for="(msg, nMIndex) in nRes.notificationMsg"
              :key="`nM-${nMIndex}`"
            >
              {{ msg }}
            </li>
          </ul>
        </div>
      </template>
    </template>
    <p v-else class="pl-2 color-black">No data available.</p>
  </b-tab>
</template>
<script>
export default {
  name: 'Notifications',
  props: ['apiResponse']
}
</script>
